import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebStorageModule } from 'ngx-store';

@NgModule({
  imports: [
    CommonModule,
    WebStorageModule
  ],
  declarations: [
    
  ],
  exports: [
  ]
})
export class FluxEngineModule { }
